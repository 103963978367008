export const localUrls = {
  /** Movido a variables de entorno */
  apiFile: 'assets/data/api.json',
  apiDevelopmentFile: 'assets/data/api.development.json',
  spanishTranslationsPath: 'assets/data/translations/es.json',
  portugueseTranslationsPath: 'assets/data/translations/pt.json',
  spanishDatepickerTranslationsPath:
    'assets/data/translations/es-datepicker.json',
  portugueseDatepickerTranslationsPath:
    'assets/data/translations/pt-datepicker.json',
};
