<div class="navbar">
  <div class="navbar__left">
    <img class="navbar__logo" src="assets\images\IsiPartsLogo.png" width="43" height="42" title="isiParts"
      alt="isiParts" (click)="goHome()" />
    <span class="navbar__brand">isiParts</span>
  </div>
  <div class="navbar__user-info userinfo-wrapper">
    <div class="userinfo-wrapper__userinfo" (click)="toggleUserInfoMenu()">
      <app-avatar class="userinfo-wrapper__icon" />
      <svg *ngIf="(authenticationService.loggedIn$ | async) === false" xmlns="http://www.w3.org/2000/svg"
        class="userinfo-wrapper__icon" width="24" height="24" fill="currentColor" class="bi bi-person-circle"
        viewBox="0 0 16 16">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
      </svg>
      <span class="userinfo-wrapper__username">{{
        (userSession$ | async)?.nombre
        }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
        class="bi bi-chevron-down userinfo-wrapper__arrow" viewBox="0 0 16 16"
        [ngClass]="{ 'userinfo-wrapper__arrow--up': userInfoMenuShow }">
        <path fill-rule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
    <ul class="userinfo-wrapper__menu" [ngClass]="{ 'userinfo-wrapper__menu--show': userInfoMenuShow }">
      <li class="userinfo-wrapper__menuitem" (click)="toggleUserInfoWindow()">
        {{ 'userinfo.config.label' | translate }}
      </li>
      <li class="userinfo-wrapper__menuitem">
        <span class="navbar__logout" (click)="logout()">
          {{ 'userinfo.go.back.label' | translate }}
        </span>
      </li>
    </ul>
  </div>
</div>

<!-- Ventana modal con la configuración del usuario -->
<div class="navbar__userinfo-modal" [ngClass]="{ 'navbar__userinfo-modal--show': !userInfoCollapsed }">
  <app-user-info (closeWindow)="toggleUserInfoWindow()"></app-user-info>
</div>
<div class="navbar__userinfo-overlay" [ngClass]="{ 'navbar__userinfo-overlay--show': !userInfoCollapsed }"></div>
