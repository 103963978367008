/** Angular core */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/** Componentes */
import { LayoutComponent } from '@layout/layout.component';

/** Módulo shared */
import { AvatarComponent, SharedModule } from '@shared/index';

/** Routing */
import { LayoutRoutingModule } from './layout-routing.module';

/** Módulo layout */
import {
  HeaderComponent,
  MenuComponent,
  UserInfoComponent,
} from '@layout/index';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    MenuComponent,
    UserInfoComponent,
    AvatarComponent,
  ],
  imports: [RouterModule, SharedModule, LayoutRoutingModule],
})
export class LayoutModule {}
