<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-describedby="modal-title"
    (click)="dismissModal()"></button>
</div>
<div class="modal-body">
  <p>
    {{ message }}
  </p>
</div>
<div class="modal-footer">
  <btn-isi
    class="wo-labor__btn"
    type="button"
    [props]="dismissButtonProps"
    (click)="dismissModal()" />
  <btn-isi
    class="wo-labor__btn"
    type="button"
    [props]="confirmButtonProps"
    (click)="closeModal()" />
</div>
