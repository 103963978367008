<form class="isi-table-wrapper">
  <div class="isi-table-wrapper__subheader">
    <div class="isi-table-wrapper__search">
      <label for="wos-table-search" class="col-xs-3 col-form-label">{{
        'global.search.label' | translate
      }}</label>
      <div class="col-xs-3">
        <input
          id="wos-table-search"
          type="text"
          class="form-control form-control-sm"
          name="searchTerm"
          [(ngModel)]="tableService.searchTerm" />
      </div>
    </div>
    <button
      type="button"
      class="btn btn-primary sidebar__toggle"
      (click)="handleRefresh()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-clockwise"
        viewBox="0 0 16 16">
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
      </svg>
    </button>
  </div>

  <!-- SIN TRADUCIR - CREO QUE NO SE ESTÁ UTILIZANDO, REVISAR -->
  <span class="col col-form-label" *ngIf="tableService.loading$ | async"
    >Cargando datos...</span
  >

  <div
    *ngIf="(tableService.loading$ | async) === false"
    class="isi-table-wrapper__container">
    <table
      class="table table-striped table-hover table-sm isi-table-wrapper__table isi-table">
      <thead>
        <tr>
          <th
            *ngFor="let header of props.tableModel.header"
            scope="col"
            class="isi-table__sortable"
            sortable="{{ header?.id }}"
            (sort)="onSort($event)">
            {{ header?.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="isi-table__tr"
          *ngFor="let data of tableService.data$ | async">
          <td
            class="isi-table__td"
            *ngFor="let item of data.listObject | keyvalue">
            <div
              class="isi-table__content-wrapper"
              (click)="selectRecord(data.fullObject)">
              <span
                *ngIf="!isDate(item.value)"
                class="isi__table__td-text"
                [ngClass]="{ 'isi-table__td--number': isNumber(item.value) }"
                >{{ item.value }}</span
              >
              <span *ngIf="isDate(item.value)" class="isi__table__td-text">{{
                getValue(item.value) | date: 'dd/MM/yyyy'
              }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="isi-table-wrapper__footer">
      <ngb-pagination
        class="text-secondary"
        [collectionSize]="(tableService.total$ | async)!"
        [(page)]="tableService.page"
        [maxSize]="5"
        [pageSize]="tableService.pageSize"
        [boundaryLinks]="true"
        [ellipses]="true">
      </ngb-pagination>

      <select
        class="form-select isi-table-wrapper__pages table-pages"
        name="pageSize"
        [(ngModel)]="tableService.pageSize">
        <option [ngValue]="5" class="table-pages__option">
          5 {{ 'table.records.per.page.label' | translate }}
        </option>
        <option [ngValue]="10" class="table-pages__option">
          10 {{ 'table.records.per.page.label' | translate }}
        </option>
        <option [ngValue]="20" class="table-pages__option">
          20 {{ 'table.records.per.page.label' | translate }}
        </option>
        <option [ngValue]="50" class="table-pages__option">
          50 {{ 'table.records.per.page.label' | translate }}
        </option>
      </select>
    </div>
  </div>
</form>
<ng-template #noData
  >{{ props.noDataMessage | translate }} - Length:
  {{ (tableService.data$ | async)?.length }}</ng-template
>
