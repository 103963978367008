// import { ErrorTypes } from "@core/index"

// export function brandsErrorHandler(errorCode: number): string{
//     switch(errorCode){
//         case ErrorTypes.WORK_ORDERS_LOAD_NOT_FOUND:
//             return 'brands.error.unknown'
//     }
// }

import {
  BrandsErrorTypes,
  FamiliesErrorTypes,
  InvoicingErrorTypes,
  MechanicsErrorTypes,
  ThirdPartyErrorTypes,
} from '@core/index';

export function shipmentsErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.SHIPMENTS_SAVE:
      return 'shipments.save.error';
    case ThirdPartyErrorTypes.SHIPMENT_DELETE:
      return 'shipments.delete.error';
    default:
      return 'global.error.unknown';
  }
}

export function activitiesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.ACTIVITIES_LOAD:
      return 'activities.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function countriesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.COUNTRIES_LOAD:
      return 'countries.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function provincesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.PROVINCES_LOAD:
      return 'provinces.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function citiesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.CITIES_LOAD:
      return 'cities.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function salesRepErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.SALESREP_LOAD:
      return 'salesrep.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function zonesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.ZONES_LOAD:
      return 'zones.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function companyTypesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case ThirdPartyErrorTypes.COMPANY_TYPES_LOAD:
      return 'company.types.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function vatErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case InvoicingErrorTypes.VAT_LOAD:
      return 'vat.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function paymentMethodErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case InvoicingErrorTypes.PAYMENT_METHODS_LOAD:
      return 'payment.methods.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function paymentDaysErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case InvoicingErrorTypes.PAYMENT_DAYS_LOAD:
      return 'payment.days.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function customerTypesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case InvoicingErrorTypes.CUSTOMER_TYPES_LOAD:
      return 'customer.types.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function mechanicsErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case MechanicsErrorTypes.MECHANICS_LOAD_UNKNOWN:
      return 'mechanics.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function brandsErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case BrandsErrorTypes.BRANDS_LOAD_UNKNOWN:
      return 'brands.load.error';
    default:
      return 'global.error.unknown';
  }
}

export function familiesErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case FamiliesErrorTypes.FAMILIES_LOAD_UNKNOWN:
      return 'brands.load.error';
    default:
      return 'global.error.unknown';
  }
}
