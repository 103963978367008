/** Angular core */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

/** Forms */
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

/** Módulo core */
import {
  AuthenticationService,
  EmptySession,
  LoginErrorTypes,
  Session,
} from '@core/index';

/** Módulo layout */
import { LayoutService, userInfoErrorHandler } from '@layout/index';

/** Módulo shared */
import {
  IsiButton,
  NotificationsService,
  TranslationPipe,
} from '@shared/index';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  providers: [TranslationPipe],
})
export class UserInfoComponent implements OnInit {
  @Output() closeWindow: EventEmitter<void>;

  protected userinfoForm: FormGroup;
  protected primaryButtonProps: IsiButton;
  protected secondaryButtonProps: IsiButton;
  private _userSession: Session;

  protected files: File[];

  constructor(
    private userinfoBuilder: FormBuilder,
    private translationPipe: TranslationPipe,
    private authenticationService: AuthenticationService,
    protected layoutService: LayoutService,
    private notificationService: NotificationsService,
  ) {
    this.userinfoForm = this.userinfoBuilder.group({
      usuario: new FormControl({ value: '', disabled: true }),
      nombre: new FormControl({ value: '', disabled: true }),
      empOmi: new FormControl({ value: '', disabled: true }),
      empOmiDes: new FormControl({ value: '', disabled: true }),
      lng: new FormControl(''),
    });
    this.closeWindow = new EventEmitter<void>();
    this.files = [];

    this.primaryButtonProps = {
      caption: this.translationPipe.transform('global.button.save'),
      type: 'submit',
      className: 'btn-primary',
      large: true,
    };
    this.secondaryButtonProps = {
      caption: this.translationPipe.transform('global.button.cancel'),
      type: 'button',
      className: 'btn-secondary',
      large: true,
    };
    this._userSession = EmptySession;
  }

  ngOnInit(): void {
    this.authenticationService.userSession$.subscribe((response) => {
      this._userSession = response;
      this.userinfoForm.patchValue({
        usuario: this._userSession.usuario,
        nombre: this._userSession.nombre,
        empOmi: this._userSession.empOmi,
        empOmiDes: this._userSession.empOmiDes,
        lng: this._userSession.lng,
      });
    });
  }

  get lng(): FormControl {
    return this.userinfoForm.get('lng') as FormControl;
  }

  get usuario(): FormControl {
    return this.userinfoForm.get('usuario') as FormControl;
  }

  get nombre(): FormControl {
    return this.userinfoForm.get('nombre') as FormControl;
  }
  get CodEmpresaSesion(): FormControl {
    return this.userinfoForm.get('empOmi') as FormControl;
  }

  get empOmiDes(): FormControl {
    return this.userinfoForm.get('empOmiDes') as FormControl;
  }

  onSubmit(): void {
    if (!this.files[0]) {
      this.closeWindow.emit();
      return;
    }

    this.userinfoForm.markAsPending();
    this.authenticationService.setUserSession({
      ...this._userSession,
      lng: this.lng.value,
    });
  }

  goBack(): void {
    this.closeWindow.emit();
    setTimeout(() => {
      this.files = [];
    }, 350);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onSelect(e: any): void {
    this.files.push(...e.addedFiles);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onRemove(e: any) {
    this.files.splice(this.files.indexOf(e), 1);
  }

  private handleError(errorStatus: number): void {
    const errorMessage: string = userInfoErrorHandler(errorStatus);

    const translatedErrorMessage = this.translationPipe.transform(errorMessage);
    this.showErrorMessage(translatedErrorMessage);
  }

  showErrorMessage(errorMessage: string): void {
    /** Marcamos el formulario con errores (error customizado) para bloquear el botón de inicio de sesión */
    this.notificationService.showError(errorMessage);
  }
}
