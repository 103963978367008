/** Angular core */
import { BehaviorSubject, Observable, take } from 'rxjs';

/** Http */
import { Injectable } from '@angular/core';

/** Servicios */
import { AuthenticationConfig } from '@core/index';

/** Otros */
import { AuthenticationConfigInterface } from '@core/models/authentication/authentication-config';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
/** Servicio encargado de cargar los parámetros de autenticación
 *  a partir de un fichero json de configuración
 */
export class AuthenticationConfigService {
  public static apiConfiguration: BehaviorSubject<AuthenticationConfig | null>;

  constructor() {
    AuthenticationConfigService.apiConfiguration =
      new BehaviorSubject<AuthenticationConfig | null>(null);
  }

  /** Cargamos el fichero de conexión con la API. Si no existiese, el return devolverá null */
  loadConfiguration(): Observable<AuthenticationConfigInterface> {
    return new Observable<AuthenticationConfigInterface>((observer) => {
      const result = environment.apiConfig;

      AuthenticationConfigService.apiConfiguration.next(
        new AuthenticationConfig(result.url, {
          authentication: result.authentication,
        }),
      );
      observer.next(
        new AuthenticationConfig(result.url, {
          authentication: result.authentication,
        }).authenticationConfig,
      );
      observer.complete();
    }).pipe(take(1));
  }
}
