import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';

@Component({
  /* eslint-disable  @angular-eslint/component-selector */
  selector: 'signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements AfterViewInit {
  @ViewChild('signPad', { static: false }) signPad: ElementRef;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private sigPadElement: any;
  private context: CanvasRenderingContext2D;
  private isDrawing!: boolean;
  private isCanvasDrawn: boolean;

  public ngAfterViewInit(): void {
    this.sigPadElement = this.signPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#393939'; /** Variable $secondary-color */
    this.isCanvasDrawn = false;
  }

  @HostListener('document:mouseup', ['$event'])
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  onMouseUp(e: any): void {
    this.isDrawing = false;
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onMouseDown(e: any): void {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onMouseMove(e: any): void {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);

      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
      this.isCanvasDrawn = true;
    }
  }

  clearSignature(): void {
    this.context.clearRect(
      0,
      0,
      this.sigPadElement.width,
      this.sigPadElement.height,
    );
    this.context.beginPath();
    this.isCanvasDrawn = false;
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private relativeCoords(event: any): { x: number; y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY,
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }

  getSignature(): string {
    const signatureImg = this.isCanvasDrawn
      ? this.sigPadElement
          .toDataURL()
          .replace(/^data:image\/(png|jpg);base64,/, '')
      : null;
    return signatureImg;
  }
}
