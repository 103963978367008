/** Angular core */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';

/** Http */
import { HttpBackend, HttpClient } from '@angular/common/http';

/** Otros */
import { localUrls } from '@data/consts';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _translationsSet: BehaviorSubject<any | null>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public translationSet$: Observable<any | null>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _datepickerTranslationsSet: BehaviorSubject<any | null>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public datepickerTranslationsSet$: Observable<any | null>;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
  ) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._translationsSet = new BehaviorSubject<any | null>(null);
    this.translationSet$ = this._translationsSet.asObservable();

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._datepickerTranslationsSet = new BehaviorSubject<any | null>(null);
    this.datepickerTranslationsSet$ = this._translationsSet.asObservable();

    /** Utilizamos el httpbackend para que esta consulta no pase por los interceptores */
    this.http = new HttpClient(httpBackend);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private getTranslationsFiles(language: string): any {
    switch (language) {
      case 'es':
        return {
          path: localUrls.spanishTranslationsPath,
          datepickerPath: localUrls.spanishDatepickerTranslationsPath,
        };
      case 'pt':
        return {
          path: localUrls.portugueseTranslationsPath,
          datepickerPath: localUrls.portugueseDatepickerTranslationsPath,
        };
      default:
        return {
          path: localUrls.spanishTranslationsPath,
          datepickerPath: localUrls.spanishDatepickerTranslationsPath,
        };
    }
  }

  /** Nos guardamos en la memoria del servicio los datos de los ficheros de traducción y
   * creamos método para devolver las traducciones
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setTranslations(language: string): any {
    const translationsFile = this.getTranslationsFiles(language);
    return new Promise<void>((resolve, reject) => {
      return this.http
        .get<any>(translationsFile.path)
        .pipe(take(1))
        .subscribe({
          next: (translationObject) => {
            this._translationsSet.next(translationObject);
            resolve();
          },
          error: (err) => {
            console.error('Error al cargar el fichero de traducciones');
            reject();
          },
        });
    });
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setDatepickerTranslations(language: string): any {
    const translationsFile = this.getTranslationsFiles(language);
    return new Promise<void>((resolve, reject) => {
      return (
        this.http
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          .get<any>(translationsFile.datepickerPath)
          .pipe(take(1))
          .subscribe({
            next: (translationObject) => {
              this._datepickerTranslationsSet.next(translationObject);
              this.datepickerTranslationsSet$ = translationObject;
              resolve();
            },
            error: (err) => {
              console.error('Error al cargar el fichero de traducciones');
              reject();
            },
          })
      );
    });
  }

  getTranslation(word: string): string {
    return this._translationsSet.getValue()[word] as string;
  }

  getDatepickerTranslation(word: string): string {
    return this._datepickerTranslationsSet.getValue()[word] as string;
  }
}
