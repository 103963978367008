const apiConfig = () => {
  let hostname: string = 'https://test-api.isiparts.com:8443';

  const locationRegex = window.location.hostname.match(
    /^(.*?)\.isiparts\.com$/i,
  );

  console.info('locationRegex', locationRegex);

  if (locationRegex !== null && locationRegex.length > 1) {
    const name = locationRegex[1];
    hostname = `https://${name}-api.isiparts.com:8443`;
  }

  return {
    url: `${hostname}/isiWeb/web/v1`,
    authentication: {
      url: `${hostname}/isiWeb/static/auth/j_spring_security_check`,
      logout: `${hostname}/isiWeb/static/auth/j_spring_security_logout`,
    },
  };
};

export const environment = {
  development: false,
  apiConfig: apiConfig(),
  posthog: {
    enabled: window.location.hostname.match(/localhost/i) === null,
    key: 'phc_3y02C4cuEDWvSdN1HfhBJ7x4eSOooPJVPirZh6azFNt',
    host: 'https://eu.i.posthog.com',
  },
  sentry: {
    enabled: window.location.hostname.match(/localhost/i) === null,
    dsn: 'https://0825b8d53e7694ba827b2c18d224c96b@o4508256865812480.ingest.de.sentry.io/4508256869613648',
  },
};
