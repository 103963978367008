/**
 * Enumeración de los errores del módulo de almacén devueltos
 * en los servicios y tratados en los metodos error-handler
 */
export enum MechanicsErrorTypes {
  MECHANICS_LOAD_UNKNOWN = 0,
}

export enum BrandsErrorTypes {
  BRANDS_LOAD_UNKNOWN = 0,
}

export enum FamiliesErrorTypes {
  FAMILIES_LOAD_UNKNOWN = 0,
}
