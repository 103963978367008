/**
 * Enumeración de los errores del módulo de presuntos devueltos
 * en los servicios y tratados en los metodos error-handler
 */
export enum PresalesErrorTypes {
  PRESALES_LOAD_UNKNOWN = 0,
  PRESALES_SAVE_UNKNOWN = 1,
  PRESALES_SHIPMENTS_LOAD_UNKNOWN = 2,
  PRESALES_SHIPMENT_SAVE_UNKNOWN = 3,
}
