<div class="sidebar">
  <button type="button" class="btn btn-primary sidebar__toggle" (click)="handleClickMenu(); collapseMenu.toggle()"
    [attr.aria-expanded]="!isCollapsed" [ngClass]="{ 'sidebar__toggle--expanded': !isCollapsed }">
    <!-- Hamburguer -->
    <svg *ngIf="isCollapsed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      class="bi bi-list" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    </svg>
    <!-- Close -->
    <svg *ngIf="!isCollapsed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
      class="bi bi-x-lg" viewBox="0 0 16 16">
      <path
        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
    </svg>
  </button>
  <div class="sidebar__main" #collapseMenu="ngbCollapse" [(ngbCollapse)]="isCollapsed" [horizontal]="true">
    <nav *ngIf="menuArray && menuArray.length > 0" class="sidebar__nav nav">

      <ul *ngFor="let menuItem of menuArray" class="nav__menus">
        <li class="nav__nav-item nav-menu">
          <button class="nav-item__toggle-btn nav-menu__toggle-btn"
            [attr.aria-expanded]="!collapsibleMenus[menuItem.id]" [ngClass]="{
              'nav-menu__toggle-btn--active': !collapsibleMenus[menuItem.id],
            }" (click)="
              collapsibleMenus[menuItem.id] = !collapsibleMenus[menuItem.id]
            ">
            <div class="nav__link">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                class="bi bi-wrench-adjustable nav-menu__icon" viewBox="0 0 16 16">
                <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z" />
                <path
                  d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
              </svg>
              <span class="nav-menu__text">{{ menuItem.title }}</span>
            </div>
          </button>
          <div [(ngbCollapse)]="collapsibleMenus[menuItem.id]">
            <ul *ngFor="let submenuItem of submenuArray" class="nav__submenus">
              <li *ngIf="submenuItem.parentId === menuItem.id" class="nav__nav-item nav-submenu">
                <button class="nav-item__toggle-btn nav-submenu__toggle-btn"
                  [attr.aria-expanded]="!collapsibleSubmenus[submenuItem.id]" [ngClass]="{
                    'nav-submenu__toggle-btn--active':
                      !collapsibleSubmenus[submenuItem.id],
                  }" (click)="
                    collapsibleSubmenus[submenuItem.id] =
                      !collapsibleSubmenus[submenuItem.id]
                  ">
                  <div class="nav__link">
                    <span class="nav-submenu__text">{{
                      submenuItem.title
                      }}</span>
                  </div>
                </button>
                <div #submenus="ngbCollapse" [(ngbCollapse)]="collapsibleSubmenus[submenuItem.id]">
                  <ul *ngFor="let option of menuOptions" (click)="
                      selectOption(option, submenuItem.id, submenuItem.parentId)
                    " class="nav__nav-items nav-options">
                    <li *ngIf="option.parentId === submenuItem.id" [ngClass]="{
                        'nav-options--active': activeOptions[option.id],
                      }">
                      <span class="ms-3 rounded nav-options__text">{{
                        option.text
                        }}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</div>
