import { LoginErrorTypes } from '@core/index';

export function userInfoErrorHandler(errorCode: number): string {
  switch (errorCode) {
    case LoginErrorTypes.PROFILE_IMAGE_NOT_FOUND:
      return 'login.profile.image.error.not.found';
    case LoginErrorTypes.PROFILE_IMAGE_UNKNOWN:
      return 'login.profile.image.error.unknown';
    default:
      return 'userinfo.error.unknown';
  }
}
