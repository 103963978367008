/**
 * Enumeración de los errores de las ayudas de las tablas de terceros (tablas
 * maestras) devueltos en los servicios y tratados en los metodos error-handler
 */
export enum ThirdPartyErrorTypes {
  SHIPMENTS_SAVE = 0,
  SHIPMENT_DELETE = 1,
  ACTIVITIES_LOAD = 2,
  COUNTRIES_LOAD = 3,
  PROVINCES_LOAD = 4,
  CITIES_LOAD = 5,
  SALESREP_LOAD = 6,
  ZONES_LOAD = 7,
  COMPANY_TYPES_LOAD = 8,
  CUSTOMERS_LOAD = 9,
}
