/**
 * Enumeración de los errores del inicio de sesión devueltos
 * en los servicios y tratados en los metodos error-handler
 */
export enum LoginErrorTypes {
  LOGIN_UNAUTHORIZED = 0,
  LOGIN_FORBIDDEN = 1,
  LOGIN_NOT_FOUND = 2,
  LOGIN_UNKNOWN = 3,
  LOGIN_SESSION_NOT_FOUND = 4,
  LOGIN_SESSION_UNKNOWN = 5,

  PROFILE_IMAGE_NOT_FOUND = 6,
  PROFILE_IMAGE_UNKNOWN = 7,
}
