/** Angular core */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

/** Módulo shared */
import {
  IsiSortableHeader,
  IsiTable,
  ReferenceHelpTableService,
  SortEvent,
} from '@shared/index';

@Component({
  /* eslint-disable  @angular-eslint/component-selector */
  selector: 'reference-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class ReferenceHelpTableComponent {
  @Input() props: IsiTable | null;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @Output() retrieveCurrentRecord: EventEmitter<any>;
  @Output() refreshTable: EventEmitter<string>;

  @ViewChildren(IsiSortableHeader) headers: QueryList<IsiSortableHeader>;

  public selectedReference: number;

  constructor(protected tableService: ReferenceHelpTableService) {
    this.props = null;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.retrieveCurrentRecord = new EventEmitter<any>();
    this.refreshTable = new EventEmitter<string>();
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
  }

  handleRefresh(): void {
    this.refreshTable.emit();
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  editRecord(data: any): void {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.retrieveCurrentRecord.emit(data);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  selectRecord(data: any): void {
    this.selectedReference = data.refer;
    this.retrieveCurrentRecord.emit(data);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  isDate(value: any): boolean {
    return value instanceof Date;
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  isNumber(value: any): boolean {
    return value instanceof Number;
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getValue(value: any): any {
    return value;
  }
}
