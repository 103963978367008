/** Angular core */
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  concatMap,
  map,
  of,
  take,
  tap,
  throwError,
} from 'rxjs';

/** Http */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

/** Módulo core */
import {
  AuthenticationConfigService,
  AuthenticationService,
  LoginErrorTypes,
} from '@core/index';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _apiUrl: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _menuStructure: BehaviorSubject<any>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public menuStructure$: Observable<any>;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public imgSrc$: Observable<any>;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._menuStructure = new BehaviorSubject<any>('');
    this.menuStructure$ = this._menuStructure.asObservable();
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  loadMenu(): Observable<any> {
    /** Si no existe el fichero de conexión con la API devuelve null */
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return of(null);
    }
    this._apiUrl = AuthenticationConfigService.apiConfiguration.value.url;

    /** Parametrizamos la llamada http a la API con los parámetros de configuración obtenidos */
    const url = `${this._apiUrl}/menu`;
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json',
    );

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    return this.http.get<any>(url, { headers }).pipe(
      take(1),
      map((result) => {
        const { data: responseArray } = result;

        return responseArray;
      }),
      /** Nos guardamos en el servicio el objeto con la estructura del menú */
      tap((response) => {
        this._menuStructure.next(response);
      }),
    );
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getMenuStructure(): Observable<any> {
    return this.menuStructure$.pipe(take(1));
  }
}
