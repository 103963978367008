/** Angular core */
import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  interval,
  map,
  of,
  take,
  tap,
  throwError,
} from 'rxjs';

/** Http */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

/** Módulo core */
import {
  Authentication,
  AuthenticationConfigService,
  AuthenticationInterface,
  EmptySession,
  LoginErrorTypes,
  MenuExpansionService,
  Session,
} from '@core/index';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _apiUrl: string;

  constructor(
    private http: HttpClient,
    private menuEspansionService: MenuExpansionService,
    private configService: AuthenticationConfigService,
  ) {
    this.configService.loadConfiguration().subscribe({
      next: (response) => {
        console.log('Configuration loaded response', response);
      },
      error: (error) => {
        console.log({ error });
      },
    });

    /** Parametrizamos la llamada http a la API con los parámetros de configuración obtenidos */
    this._apiUrl = AuthenticationConfigService.apiConfiguration.value.url;

    interval(60000).subscribe({
      next: () => {
        if (this.loggedIn()) {
          this.http.get<any>(`${this._apiUrl}/keep-alive`).subscribe();
        }
      },
    });
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  login(authObject: AuthenticationInterface): Observable<any | null> {
    /** Si no existe el fichero de conexión con la API devuelve null */
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      console.error(
        'Error en !AuthenticationConfigService.apiConfiguration?.value)',
      );

      return throwError(() => null);
    }

    /** Obtenemos los parámetros de conexión a partir del fichero de configuración */
    const auth = new Authentication();
    auth.setAuthObject(authObject);

    const body = auth.getAuthenticationRequestData();
    const url =
      AuthenticationConfigService.apiConfiguration.value.authentication
        .authentication.url;

    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded',
    );

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    return this.http.post<any>(url, body, { headers }).pipe(
      take(1),
      catchError((httpError) =>
        throwError(() => {
          console.error('Error catch login', httpError);
          return this.handleLoginError(httpError.status);
        }),
      ),
    );
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getAuthenticatedUser(): Observable<any> {
    const url: string = `${this._apiUrl}/global-data`;

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    return this.http.get<any>(url).pipe(
      take(1),
      map((response) => {
        const { usuario, nombre, lng, empOmi, empOmiDes } = response.data;

        const userSession: Session = {
          usuario,
          nombre,
          lng,
          empOmi,
          empOmiDes,
        };

        posthog.identify(usuario, { usuario, lng, empOmi, empOmiDes });

        return userSession;
      }),
      tap({
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        next: (response: any) => {
          if (response) {
            this.setUserSession(response);
            return response;
          }
          throwError(() => of(response));
        },
      }),
      catchError((httpError) =>
        throwError(() => {
          console.error('catch en getAuthenticatedUser', httpError);
          this.handleLoginError(httpError.status);
        }),
      ),
    );
  }

  handleLoginError(errorStatus: number): number {
    switch (errorStatus) {
      case 404:
        return LoginErrorTypes.LOGIN_NOT_FOUND;
      case 401:
        return LoginErrorTypes.LOGIN_UNAUTHORIZED;
      case 403:
        return LoginErrorTypes.LOGIN_FORBIDDEN;
      case 500:
        return LoginErrorTypes.LOGIN_UNKNOWN;
      default:
        return LoginErrorTypes.LOGIN_UNKNOWN;
    }
  }

  handleSessionError(errorStatus: number): number {
    switch (errorStatus) {
      case 404:
        return LoginErrorTypes.LOGIN_SESSION_NOT_FOUND;
      case 500:
        return LoginErrorTypes.LOGIN_SESSION_UNKNOWN;
      default:
        return LoginErrorTypes.LOGIN_SESSION_UNKNOWN;
    }
  }

  public handleLogout(): void {
    const url =
      AuthenticationConfigService.apiConfiguration.value.authentication
        .authentication.logout;

    this.http
      .post<any>(url, null)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.menuEspansionService.setMenuOpen(false);
          this.removeSession();
          posthog.reset();
        },
        error: (error) => {
          console.error('error logout', error);
        },
      });
  }

  public checkUserLoggedIn(fetch: boolean = false): Session | null {
    if (fetch) {
      console.info('Fetching session from server');
      this.getAuthenticatedUser().subscribe({
        next: (response) => {
          this.saveSession(response);
        },
        error: () => {
          this.handleLogout();

          if (!window.location.pathname.includes('login')) {
            window.location.href = '/login';
          }
        },
      });
    }

    const userSession = sessionStorage.getItem('isiparts-session');

    return userSession ? JSON.parse(userSession) : null;
  }

  setUserSession(userSessionObject: Session): void {
    console.log('setUserSession', userSessionObject);
    this.saveSession(userSessionObject);
  }

  private saveSession(session: Session): void {
    sessionStorage.setItem('isiparts-session', JSON.stringify(session));
  }

  private removeSession(): void {
    sessionStorage.removeItem('isiparts-session');
  }

  public userSession(): Session {
    const userSession = sessionStorage.getItem('isiparts-session');

    return userSession ? JSON.parse(userSession) : EmptySession;
  }

  get userSession$(): Observable<Session> {
    return of(this.userSession());
  }

  public loggedIn(): boolean {
    return (
      sessionStorage.getItem('isiparts-session') !== null &&
      this.userSession().nombre !== EmptySession.nombre
    );
  }

  get loggedIn$(): Observable<boolean> {
    return of(this.loggedIn());
  }

  public profileImage(): string {
    return `${this._apiUrl}/profile-image?u=${btoa(sessionStorage.getItem('isiparts-session'))}`;
  }

  get profileImage$(): Observable<string> {
    return of(this.profileImage());
  }
}
