/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import posthog from 'posthog-js';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.posthog.enabled) {
  posthog.init(environment.posthog.key, {
    api_host: environment.posthog.host,
    person_profiles: 'identified_only',
  });
}

console.info(`Posthog ${environment.posthog.enabled ? 'enabled' : 'disabled'}`);

if (environment.sentry.enabled) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https?:\/\/([a-z0-9]+\.)*isiparts\.com/,
      /^https?:\/\/([a-z0-9]+\.)*isiparts\.pages\.dev/,
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, //  Capture 100% of the transactions
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

console.info(`Sentry ${environment.sentry.enabled ? 'enabled' : 'disabled'}`);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
