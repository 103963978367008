/** Angular core */
import { NgModule } from '@angular/core';

/** Routing */
import { RouterModule, Routes } from '@angular/router';

/** Módulo core */
import { AuthenticationGuard } from '@core/index';

/** Módulo shared */
import { SharedModule } from '@shared/shared.module';

/** Módulo layout */
import { LayoutComponent } from '@layout/index';

const routes: Routes = [
  {
    path: 'layout',
    component: LayoutComponent,
    children: [
      {
        path: 'or',
        loadChildren: () =>
          import('@modules/work-orders/work-orders.module').then(
            (m) => m.WorkOrdersModule,
          ),
        canLoad: [AuthenticationGuard],
      },
      {
        path: 'presuntos',
        loadChildren: () =>
          import('@modules/presales/presales.module').then(
            (m) => m.PresalesModule,
          ),
        canLoad: [AuthenticationGuard],
      },
      {
        path: 'taller',
        loadChildren: () =>
          import('@modules/workshop/workshop.module').then(
            (m) => m.WorkshopModule,
          ),
        canLoad: [AuthenticationGuard],
      },
      {
        path: 'home',
        loadChildren: () =>
          import('@modules/home/home.module').then((m) => m.HomeModule),
        canLoad: [AuthenticationGuard],
      },
    ],
  },
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
