<div class="signature">
  <button
    class="signature__clear"
    title="{{ 'global.signature.clear.tooltip' | translate }}">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      class="bi bi-x"
      viewBox="0 0 16 16"
      (click)="clearSignature()">
      <path
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  </button>
  <canvas
    class="signature__canvas"
    id="signature_canvas"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (touchmove)="onMouseMove($event)"
    (touchstart)="onMouseDown($event)"
    #signPad>
  </canvas>
</div>
