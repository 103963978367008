import { AfterViewInit, Component } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturesPadComponent implements AfterViewInit {
  protected signaturePad: SignaturePad;

  ngAfterViewInit(): void {
    const canvas = document.querySelector('canvas');

    this.signaturePad = new SignaturePad(canvas);
  }

  clearSignature(): void {
    this.signaturePad.clear();
  }
}
