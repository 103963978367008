import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  NgOptimizedImage,
} from '@angular/common';

/** Angular core */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Forms */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Http */
import { HttpClientModule } from '@angular/common/http';

/** Módulo shared */
import {
  AvatarComponent,
  ConfirmMessageComponent,
  CustomerHelpTableComponent,
  FormatterService,
  IsiButtonComponent,
  IsiDateComponent,
  IsiSortableHeader,
  IsiTableComponent,
  NotificationsComponent,
  ReferenceHelpTableComponent,
  ReferencesByCoincidenceTableComponent,
  SignaturePadComponent,
  SignaturesPadComponent,
  TranslationPipe,
} from '@shared/index';

/** Librerías */
import {
  NgbCollapse,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbToast,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    TranslationPipe,
    NotificationsComponent,
    IsiButtonComponent,
    IsiTableComponent,
    IsiSortableHeader,
    IsiDateComponent,
    IsiSortableHeader,
    CustomerHelpTableComponent,
    SignaturePadComponent,
    SignaturesPadComponent,
    ConfirmMessageComponent,
    ReferencesByCoincidenceTableComponent,
    ReferenceHelpTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbToast,
    NgbCollapse,
    NgbNavModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    NgxDropzoneModule,
    NgbModalModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxSpinnerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslationPipe,
    NotificationsComponent,
    NgbToast,
    NgbCollapse,
    NgbNavModule,
    IsiButtonComponent,
    IsiTableComponent,
    IsiDateComponent,
    NgbDatepickerModule,
    NgbPaginationModule,
    IsiSortableHeader,
    CustomerHelpTableComponent,
    SignaturePadComponent,
    SignaturesPadComponent,
    NgxDropzoneModule,
    NgbModalModule,
    ConfirmMessageComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    ReferencesByCoincidenceTableComponent,
    ReferenceHelpTableComponent,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DecimalPipe,
    DatePipe,
    NgOptimizedImage,
    { provide: NgbDateParserFormatter, useClass: FormatterService },
    provideNgxMask(),
  ],
})
export class SharedModule {}
