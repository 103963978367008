import { SortColumn } from '@shared/index';

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export function sort(
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  data: any[],
  column: SortColumn,
  direction: string,
  /* eslint-disable  @typescript-eslint/no-explicit-any */
): any[] {
  if (direction === '' || column === '') {
    return data;
  }
  return [...data].sort((a, b) => {
    const res = compare(a.listObject[column], b.listObject[column]);
    return direction === 'asc' ? res : -res;
  });
}
