import { Directive, EventEmitter, Input, Output } from '@angular/core';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type SortColumn = any;
export type SortDirection = 'asc' | 'desc' | '';

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  /* eslint-disable  @angular-eslint/directive-selector */
  selector: 'th[sortable]',
  /* eslint-disable  @angular-eslint/no-host-metadata-property */
  host: {
    '[class.isi-table__asc]': 'direction === "asc"',
    '[class.isi-table__desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
/* eslint-disable  @angular-eslint/directive-class-suffix */
export class IsiSortableHeader {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
