<div class="uinfo-container uinfo">
  <div class="uinfo__header">
    <h2 class="uinfo__title">{{ 'userinfo.header.title' | translate }}</h2>
  </div>
  <form [formGroup]="userinfoForm" class="uinfo__body" (ngSubmit)="onSubmit()">
    <fieldset class="uinfo__fieldset">
      <div class="row uinfo__row gx-2">
        <label class="form-label" for="uinfo-usuario">{{
          'userinfo.username.label' | translate
        }}</label>
        <div class="col-md-2">
          <input
            id="wo-usuario"
            formControlName="usuario"
            class="form-control form-control-sm" />
        </div>
        <div class="col-md">
          <input
            id="wo-nombre"
            formControlName="nombre"
            class="form-control form-control-sm" />
        </div>
      </div>
      <div class="row uinfo__row gx-2">
        <label class="form-label" for="uinfo-empOmiDes">{{
          'userinfo.company.label' | translate
        }}</label>
        <div class="col-md-2">
          <input
            id="wo-empOmi"
            formControlName="empOmi"
            class="form-control form-control-sm" />
        </div>
        <div class="col-md">
          <input
            id="wo-empOmiDes"
            formControlName="empOmiDes"
            class="form-control form-control-sm" />
        </div>
      </div>
      <div class="row uinfo__row">
        <div class="col-md-6">
          <label class="form-label" for="uinfo-lng">{{
            'userinfo.language.label' | translate
          }}</label>
          <div class="uinfo__language">
            <select
              class="form-select form-select-sm uinfo__language-select"
              formControlName="lng">
              <option value="es">
                {{ 'global.language.spanish' | translate }}
              </option>
              <option value="pt">
                {{ 'global.language.portuguese' | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
        <ngx-dropzone-label>Arrastrar aquí</ngx-dropzone-label>
        <ngx-dropzone-image-preview
          ngProjectAs="ngx-dropzone-preview"
          [removable]="true"
          (removed)="onRemove($event)"
          *ngFor="let f of files"
          [file]="f">
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
    </fieldset>
    <footer class="uinfo-container__footer">
      <btn-isi
        class="uinfo-container__btn"
        type="button"
        [props]="secondaryButtonProps"
        (click)="goBack()" />
      <btn-isi
        class="uinfo-container__btn"
        type="submit"
        [props]="primaryButtonProps" />
    </footer>
  </form>
</div>
<app-notifications aria-live="polite" aria-atomic="true"></app-notifications>
