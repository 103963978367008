/** Angular core */
import { NgModule } from '@angular/core';

/** Módulo shared */
import { SharedModule, TranslationPipe } from '@shared/index';

@NgModule({
  declarations: [],
  providers: [TranslationPipe],
  imports: [SharedModule],
})
export class CoreModule {}
