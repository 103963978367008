/** Angular core */
import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';

/** Menú core */
import { AuthenticationService, MenuExpansionService } from '@core/index';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @HostListener('window:resize', ['$event'])
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  onResize(event: Event) {
    this.largeSize = window.innerWidth > 1024;
  }

  protected isLoggedIn$: Observable<boolean>;
  protected largeSize: boolean;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  protected menuStructure: any;

  constructor(
    private authenticationService: AuthenticationService,
    protected menuExpansionService: MenuExpansionService,
  ) {
    this.isLoggedIn$ = this.authenticationService.loggedIn$;
    this.largeSize = true;
  }
}
