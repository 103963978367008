/** Angular core */
import { Injectable } from '@angular/core';

/** Servicios */
import { TranslationService } from '@shared/index';

/** Librerías */
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class I18nService extends NgbDatepickerI18n {
  constructor(private translationService: TranslationService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return this.translationService.getDatepickerTranslation('weekdays')[
      weekday - 1
    ];
  }

  getMonthShortName(month: number): string {
    return this.translationService.getDatepickerTranslation('months')[
      month - 1
    ];
  }
  getMonthFullName(month: number): string {
    return this.translationService.getDatepickerTranslation('months')[month];
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
