/** Angular core */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/** Módulo shared */
import { IsiButton, TranslationPipe } from '@shared/index';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss'],
  providers: [TranslationPipe],
})
export class ConfirmMessageComponent {
  @Input() title: string;
  @Input() message: string;
  @Output() dismiss: EventEmitter<void>;
  /* eslint-disable  @angular-eslint/no-output-native */
  @Output() close: EventEmitter<void>;

  protected confirmButtonProps: IsiButton;
  protected dismissButtonProps: IsiButton;

  constructor(private translationPipe: TranslationPipe) {
    this.dismiss = new EventEmitter<void>();
    this.close = new EventEmitter<void>();

    this.confirmButtonProps = {
      caption: this.translationPipe.transform('global.button.confirm'),
      type: 'button',
      className: 'btn-primary',
      large: true,
    };
    this.dismissButtonProps = {
      caption: this.translationPipe.transform('global.button.cancel'),
      type: 'button',
      className: 'btn-secondary',
      large: true,
    };
  }

  dismissModal(): void {
    this.dismiss.emit();
  }

  closeModal(): void {
    this.close.emit();
  }
}
