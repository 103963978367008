/**
 * Conjunto de servicios para las mutaciones de la bbdd dentro del módulo de
 * terceros (tablas maestras) y consumido transversalmente en toda la aplicación
 */
/** Angular core */
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  concatMap,
  map,
  of,
  take,
  tap,
  throwError,
} from 'rxjs';

/** Http */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

/** Módulo core */
import {
  APIResponse,
  AuthenticationConfigService,
  AuthenticationService,
  BackendResponse,
  Session,
  ThirdPartyErrorTypes,
  activitiesErrorHandler,
  companyTypesErrorHandler,
  countriesErrorHandler,
  salesRepErrorHandler,
  shipmentsErrorHandler,
  zonesErrorHandler,
} from '@core/index';
import { environment } from 'environments/environment';

/** Módulo shared */
import { TranslationPipe } from '@shared/index';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyHelpsService {
  private headers: HttpHeaders;
  private _apiUrl: string;

  /** Observables y sus correspondientes subjects para los diferentes listados
   * Subject: private para proteger el acceso
   * Observable: public para ser accedido de forma controlada mediante el método pipe
   */
  /** CREAR MODELOS PARA LAS DIFERENTES TABLAS */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public salesreps$: Observable<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _salesreps: BehaviorSubject<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public provinces$: Observable<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _provinces: BehaviorSubject<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public cities$: Observable<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _cities: BehaviorSubject<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public zones$: Observable<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _zones: BehaviorSubject<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public activities$: Observable<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _activities: BehaviorSubject<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public companyTypes$: Observable<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _companyTypes: BehaviorSubject<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public countries$: Observable<any[]>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _countries: BehaviorSubject<any[]>;
  private _errorUpdateShipment: BehaviorSubject<string>;
  public errorUpdateShipment$: Observable<string>;
  private _errorDeleteShipment: BehaviorSubject<string>;
  public errorDeleteShipment$: Observable<string>;
  private _errorActivitiesList: BehaviorSubject<string>;
  public errorActivitiesList$: Observable<string>;
  private _errorCountriesList: BehaviorSubject<string>;
  public errorCountriesList$: Observable<string>;
  private _errorProvincesList: BehaviorSubject<string>;
  public errorProvincesList$: Observable<string>;
  private _errorCitiesList: BehaviorSubject<string>;
  public errorCitiesList$: Observable<string>;
  private _errorSalesrepList: BehaviorSubject<string>;
  public errorSalesrepList$: Observable<string>;
  private _errorZonesList: BehaviorSubject<string>;
  public errorZonesList$: Observable<string>;
  private _errorCompanyTypesList: BehaviorSubject<string>;
  public errorCompanyTypesList$: Observable<string>;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private translationPipe: TranslationPipe,
  ) {
    /** Parametrizamos la llamada http a la API con los parámetros de configuración obtenidos */
    this._apiUrl = AuthenticationConfigService.apiConfiguration.value
      ? AuthenticationConfigService.apiConfiguration.value?.url
      : '';

    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._provinces = new BehaviorSubject<any[]>([]);
    this.provinces$ = this._provinces.asObservable();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._cities = new BehaviorSubject<any[]>([]);
    this.cities$ = this._cities.asObservable();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._salesreps = new BehaviorSubject<any[]>([]);
    this.salesreps$ = this._salesreps.asObservable();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._zones = new BehaviorSubject<any[]>([]);
    this.zones$ = this._zones.asObservable();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._activities = new BehaviorSubject<any[]>([]);
    this.activities$ = this._activities.asObservable();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._companyTypes = new BehaviorSubject<any[]>([]);
    this.companyTypes$ = this._companyTypes.asObservable();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this._countries = new BehaviorSubject<any[]>([]);
    this.countries$ = this._countries.asObservable();
    this._errorUpdateShipment = new BehaviorSubject<string>(null);
    this.errorUpdateShipment$ = this._errorUpdateShipment.asObservable();
    this._errorDeleteShipment = new BehaviorSubject<string>(null);
    this.errorDeleteShipment$ = this._errorDeleteShipment.asObservable();
    this._errorActivitiesList = new BehaviorSubject<string>(null);
    this.errorActivitiesList$ = this._errorActivitiesList.asObservable();
    this._errorCountriesList = new BehaviorSubject<string>(null);
    this.errorCountriesList$ = this._errorCountriesList.asObservable();
    this._errorProvincesList = new BehaviorSubject<string>(null);
    this.errorProvincesList$ = this._errorProvincesList.asObservable();
    this._errorCitiesList = new BehaviorSubject<string>(null);
    this.errorCitiesList$ = this._errorCitiesList.asObservable();
    this._errorSalesrepList = new BehaviorSubject<string>(null);
    this.errorSalesrepList$ = this._errorSalesrepList.asObservable();
    this._errorZonesList = new BehaviorSubject<string>(null);
    this.errorZonesList$ = this._errorZonesList.asObservable();
    this._errorCompanyTypesList = new BehaviorSubject<string>(null);
    this.errorCompanyTypesList$ = this._errorCompanyTypesList.asObservable();
  }

  /** Ponemos los streams de los observables a null para reiniciar */
  resetErrorCode(): void {
    this._errorUpdateShipment.next(null);
    this._errorDeleteShipment.next(null);
    this._errorActivitiesList.next(null);
    this._errorCitiesList.next(null);
    this._errorProvincesList.next(null);
    this._errorSalesrepList.next(null);
    this._errorCountriesList.next(null);
    this._errorZonesList.next(null);
    this._errorCompanyTypesList.next(null);
  }

  /** Método para la obtención de clientes, consumido transve */
  getCustomers(): Observable<BackendResponse> {
    let url = '';
    return this.authenticationService.userSession$.pipe(
      tap(
        (userSession: Session) =>
          (url = `${this._apiUrl}/empresas/${userSession.empOmi}/clientes`),
      ),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(
          take(1),
          catchError(() => {
            /** De base devolvemos error 500. Se puede ampliar para aceptar el que venga en httpError.status */
            return throwError(() => {
              500;
            });
          }),
        );
      }),
    );
  }

  /** Método para obtener información del cliente seleccionado */
  getCustomerData(customerCode: number): Observable<BackendResponse> {
    let url = '';
    return this.authenticationService.userSession$.pipe(
      tap(
        (userSession: Session) =>
          (url = `${this._apiUrl}/empresas/${userSession.empOmi}/clientes?cli=${customerCode}`),
      ),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(take(1));
      }),
      catchError(() => {
        /** De base devolvemos error 500. Se puede ampliar para aceptar el que venga en httpError.status */
        return throwError(() => {
          500;
        });
      }),
    );
  }

  /** Método para obtener la lista de actividades */
  loadActivities(): void {
    const url = `${this._apiUrl}/actividades`;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.activities$ = this.http.get<any>(url).pipe(
      map((response) => {
        if (!response || !response.success) {
          const errorMessage = this.translationPipe.transform(
            activitiesErrorHandler(ThirdPartyErrorTypes.ACTIVITIES_LOAD),
          );
          this._errorActivitiesList.next(errorMessage);
        }
        if (response.success) {
          const { data: activities } = response;
          return activities;
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error(
              'ERROR: Catch error al cargar las actividades',
              error,
            );

          this._errorActivitiesList.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            activitiesErrorHandler(ThirdPartyErrorTypes.ACTIVITIES_LOAD),
          );
          this._errorActivitiesList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Método para obtener los países */
  loadCountries(): void {
    const url = `${this._apiUrl}/paises`;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.countries$ = this.http.get<any>(url).pipe(
      map((response) => {
        if (!response || !response.success) {
          const errorMessage = this.translationPipe.transform(
            countriesErrorHandler(ThirdPartyErrorTypes.COUNTRIES_LOAD),
          );
          this._errorCountriesList.next(errorMessage);
        }

        if (response.success) {
          const { data: countries } = response;
          return countries;
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error('ERROR: Catch error al cargar los países', error);

          this._errorCountriesList.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            activitiesErrorHandler(ThirdPartyErrorTypes.COUNTRIES_LOAD),
          );
          this._errorCountriesList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Método para obtener las provincias */
  loadProvinces(): void {
    const url = `${this._apiUrl}/provincias`;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.provinces$ = this.http.get<any>(url).pipe(
      map((response) => {
        if (!response || !response.success) {
          const errorMessage = this.translationPipe.transform(
            activitiesErrorHandler(ThirdPartyErrorTypes.PROVINCES_LOAD),
          );
          this._errorProvincesList.next(errorMessage);
        }

        if (response.success) {
          const { data: provinces } = response;
          return provinces;
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error('ERROR: Catch error al cargar las provincias', error);

          this._errorProvincesList.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            activitiesErrorHandler(ThirdPartyErrorTypes.PROVINCES_LOAD),
          );
          this._errorProvincesList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Método para cargar las ciudades */
  loadCities(provinceCode: string): void {
    /** Inicializamos primero, ya que hasta que no esté informada la provincia no podemos listar poblaciones */
    this._cities.next([]);

    const url = `${this._apiUrl}/provincias/${provinceCode}/poblaciones`;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.cities$ = this.http.get<any>(url).pipe(
      map((response) => {
        if (!response || !response.success) {
          const errorMessage = this.translationPipe.transform(
            activitiesErrorHandler(ThirdPartyErrorTypes.CITIES_LOAD),
          );
          this._errorCitiesList.next(errorMessage);
        }

        if (response.success) {
          const { data } = response;

          const cities = data.filter(
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            (item: any, index: number, self: any[]) =>
              index === self.findIndex((city) => city.pobdes === item.pobdes),
          );

          return cities;
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error(
              'ERROR: Catch error al cargar las poblaciones',
              error,
            );

          this._errorCitiesList.next(error.messge);
        } else {
          const errorMessage = this.translationPipe.transform(
            activitiesErrorHandler(ThirdPartyErrorTypes.CITIES_LOAD),
          );
          this._errorCitiesList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Método de carga de comerciales */
  loadSalesreps(): void {
    /** Si no existe el fichero de conexión con la API devuelve null */
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return;
    }
    let language = 'es';
    let url = '';
    this.salesreps$ = this.authenticationService.userSession$.pipe(
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/vendedores?lng=${language}`;
      }),
      concatMap(() => {
        return this.http.get<APIResponse>(url).pipe(
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          map((response: any) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                salesRepErrorHandler(ThirdPartyErrorTypes.SALESREP_LOAD),
              );
              this._errorSalesrepList.next(errorMessage);
            }
            if (response.success) {
              const { data: salesreps } = response;
              return salesreps;
            }
          }),
          catchError((httpError: HttpErrorResponse) => {
            /** Cargamos el código de error en un observable para ser recogido en su
             *  subscripción dentro del componente: allí se muestra el error.
             */
            if (httpError?.error?.message) {
              const { error } = httpError;

              /** LOG */
              if (environment.development)
                console.error(
                  'ERROR: Catch error al cargar las poblaciones',
                  error,
                );

              this._errorSalesrepList.next(error.message);
            } else {
              const errorMessage = this.translationPipe.transform(
                salesRepErrorHandler(ThirdPartyErrorTypes.SALESREP_LOAD),
              );
              this._errorSalesrepList.next(errorMessage);
            }
            return of(null);
          }),
        );
      }),
    );
  }

  /** Método de carga de zonas */
  loadZones(): void {
    /** Si no existe el fichero de conexión con la API devuelve null */
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return;
    }
    let language = 'es';
    let url = '';
    this.zones$ = this.authenticationService.userSession$.pipe(
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/zonas?lng=${language}`;
      }),
      concatMap(() => {
        return this.http.get<APIResponse>(url).pipe(
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          map((response: any) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                zonesErrorHandler(ThirdPartyErrorTypes.ZONES_LOAD),
              );
              this._errorZonesList.next(errorMessage);
            }
            if (response.success) {
              const { data: zones } = response;
              return zones;
            }
          }),
          catchError((httpError: HttpErrorResponse) => {
            /** Cargamos el código de error en un observable para ser recogido en su
             *  subscripción dentro del componente: allí se muestra el error.
             */
            if (httpError?.error?.message) {
              const { error } = httpError;

              /** LOG */
              if (environment.development)
                console.error(
                  'ERROR: Catch error al cargar las poblaciones',
                  error,
                );

              this._errorZonesList.next(error.message);
            } else {
              const errorMessage = this.translationPipe.transform(
                zonesErrorHandler(ThirdPartyErrorTypes.ZONES_LOAD),
              );
              this._errorZonesList.next(errorMessage);
            }
            return of(null);
          }),
        );
      }),
    );
  }

  /** Carga de tipos de empresa */
  loadCompanyTypes(): void {
    const url = `${this._apiUrl}/tipos-empresa`;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.companyTypes$ = this.http.get<any>(url).pipe(
      map((response) => {
        if (!response || !response.success) {
          const errorMessage = this.translationPipe.transform(
            companyTypesErrorHandler(ThirdPartyErrorTypes.COMPANY_TYPES_LOAD),
          );
          this._errorCompanyTypesList.next(errorMessage);
        }
        if (response.success) {
          const { data: companyTypes } = response;
          return companyTypes;
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error(
              'ERROR: Catch error al cargar las poblaciones',
              error,
            );

          this._errorCompanyTypesList.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            companyTypesErrorHandler(ThirdPartyErrorTypes.COMPANY_TYPES_LOAD),
          );
          this._errorCompanyTypesList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Método para crear direcciones de envío  */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  createShipment(request: any): Observable<BackendResponse> {
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return of(null);
    }

    let url = '';
    let language = 'es';
    return this.authenticationService.userSession$.pipe(
      take(1),
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/direcciones-envio?lng=${language}`;
      }),
      concatMap(() => {
        return this.http.post<APIResponse>(url, request).pipe(
          take(1),
          map((apiResponse: APIResponse) => {
            const response: BackendResponse = {
              success: apiResponse.success,
              data: apiResponse.data,
            };

            return response;
          }),
          catchError((httpError: HttpErrorResponse) => {
            /** Cargamos el código de error en un observable para ser recogido en su
             *  subscripción dentro del componente: allí se muestra el error.
             */
            if (httpError?.error?.message) {
              const { error } = httpError;

              /** LOG */
              if (environment.development)
                console.error(
                  'ERROR: Catch error crear dirección de envío',
                  error,
                );

              this._errorUpdateShipment.next(error.message);
            } else {
              const errorMessage = this.translationPipe.transform(
                shipmentsErrorHandler(ThirdPartyErrorTypes.SHIPMENTS_SAVE),
              );
              this._errorUpdateShipment.next(errorMessage);
            }
            const response: BackendResponse = {
              success: false,
              data: null,
            };
            return of(response);
          }),
        );
      }),
    );
  }

  /** Método para modificar direcciones de envío  */
  updateShipment(
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    request: any,
    cif: string,
    dup: string,
    nroenv: number,
  ): Observable<BackendResponse> {
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return of(null);
    }

    let url = '';
    let language = 'es';
    const key: string = `${cif}.${dup}.${nroenv}`;
    return this.authenticationService.userSession$.pipe(
      take(1),
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/direcciones-envio/${key}?lng=${language}`;
      }),
      concatMap(() => {
        return this.http.put<APIResponse>(url, request).pipe(
          take(1),
          map((apiResponse: APIResponse) => {
            const response: BackendResponse = {
              success: apiResponse.success,
              data: apiResponse.data,
            };

            return response;
          }),
          catchError((httpError: HttpErrorResponse) => {
            /** Cargamos el código de error en un observable para ser recogido en su
             *  subscripción dentro del alta/modificción de presuntos: allí se muestra el error.
             */
            if (httpError?.error?.message) {
              const { error } = httpError;

              /** LOG */
              if (environment.development)
                console.error('ERROR: Catch error modificar presunto', error);

              this._errorUpdateShipment.next(error.message);
            } else {
              const errorMessage = this.translationPipe.transform(
                shipmentsErrorHandler(ThirdPartyErrorTypes.SHIPMENTS_SAVE),
              );
              this._errorUpdateShipment.next(errorMessage);
            }
            const response: BackendResponse = {
              success: false,
              data: null,
            };
            return of(response);
          }),
        );
      }),
    );
  }

  /** Método para eliminar direcciones de envío */
  deleteShipment(
    cif: string,
    dup: string,
    nroenv: number,
  ): Observable<BackendResponse> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json',
    );
    let url = '';
    let language = 'es';
    const key: string = `${cif}.${dup}.${nroenv}`;

    return this.authenticationService.userSession$.pipe(
      take(1),
      tap((userSession: Session) => {
        language = userSession.lng ? userSession.lng : language;
        url = `${this._apiUrl}/direcciones-envio/${key}?lng=${language}`;
      }),
      concatMap(() => this.http.delete<APIResponse>(url, { headers })),
      take(1),
      map((apiResponse: APIResponse) => {
        const response: BackendResponse = {
          success: apiResponse.success,
          data: apiResponse.data,
        };

        return response;
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del alta/modificción de presuntos: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error(
              'ERROR: Catch error borrar direcciones de envío',
              error,
            );

          this._errorDeleteShipment.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            shipmentsErrorHandler(ThirdPartyErrorTypes.SHIPMENT_DELETE),
          );
          this._errorDeleteShipment.next(errorMessage);
        }
        const response: BackendResponse = {
          success: false,
          data: null,
        };
        return of(response);
      }),
    );
  }
}
