/**
 * Interfaz para tipar el objeto sesión devuelto por la API
 */
export const EmptySession: Session = {
  usuario: '',
  nombre: '',
  lng: '',
  empOmi: '',
  empOmiDes: '',
};

export interface Session {
  usuario: string;
  nombre: string;
  lng: string;
  empOmi: string;
  empOmiDes: string;
}
