import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuExpansionService {
  private _menuOpen: BehaviorSubject<boolean>;
  public menuOpen$: Observable<boolean>;

  constructor() {
    this._menuOpen = new BehaviorSubject<boolean>(false);
    this.menuOpen$ = this._menuOpen.asObservable();
  }

  setMenuOpen(menuOpen: boolean) {
    this._menuOpen.next(menuOpen);
  }

  getMenuOpen(): Observable<boolean> {
    return this.menuOpen$.pipe(take(1));
  }
}
