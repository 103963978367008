/** Angular core */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, Subscription, take } from 'rxjs';

/** Routing */
import { Router } from '@angular/router';

/** Módulo core */
import { AuthenticationService, MenuExpansionService } from '@core/index';

/** Módulo layout */
import { LayoutService } from '@layout/index';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @Input() menuStructure: any;
  @Input() isCollapsed: boolean;
  @Output() toggle: EventEmitter<void> = new EventEmitter<void>();

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @ViewChildren('menus') menus: QueryList<any> | null;

  private _menuOpenMenuSubscription: Subscription;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private userSession$: Observable<any>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  protected menuArray: any[];
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  protected submenuArray: any[];
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  protected menuOptions: any[];

  /** Arrays que indican si los diferentes menús y submenús están
   *  colapsados  o no (colapsado = true, expandido = false) */
  protected collapsibleMenus: boolean[];
  protected collapsibleSubmenus: boolean[];
  protected activeOptions: boolean[];

  constructor(
    protected authenticationService: AuthenticationService,
    private menuExpansionService: MenuExpansionService,
    private layoutService: LayoutService,
    private router: Router,
  ) {
    this.isCollapsed = true;
    this.menuArray = [];
    this.submenuArray = [];
    this.menuOptions = [];
    this._menuOpenMenuSubscription = new Subscription();
    this.userSession$ = this.authenticationService.userSession$.pipe(take(1));
    this.menus = null;
    this.collapsibleMenus = [];
    this.collapsibleSubmenus = [];
    this.activeOptions = [];
  }

  ngOnInit(): void {
    this.layoutService
      .loadMenu()
      .subscribe((menuStructure) => this.loadMenuStructure(menuStructure));
  }

  handleClickMenu(): void {
    this.menuExpansionService.setMenuOpen(this.isCollapsed);
  }

  goHome(): void {
    this.router.navigate(['layout/home']);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  loadMenuStructure(responseArray: any): void {
    /** Cargamos el menú */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    this.menuArray = responseArray.map((item: any) => {
      const { text: title, childs, id } = item;

      this.collapsibleMenus[id] = true;
      return {
        id,
        title,
        childs,
      };
    });

    /** Cargamos submenu */
    this.submenuArray = [];
    this.menuArray.forEach((menuItem) => {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      menuItem.childs.map((item: any) => {
        const { text: title, childs, id } = item;
        this.collapsibleSubmenus[id] = true;
        this.submenuArray.push({
          id,
          title,
          childs,
          parentId: menuItem.id,
        });
      });
    });

    /** Cargamos opciones */
    this.menuOptions = [];
    this.submenuArray.forEach((submenuItem) => {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      submenuItem.childs.map((item: any) => {
        const { text, id, route } = item;

        /** Workaround para eliminar las rutas que no sean lista de presuntos
         *  hasta que se puedan eliminar desde el backend
         */
        if (route !== 'presunto/new' && route !== 'presunto/history') {
          this.activeOptions[id] = false;
          this.menuOptions.push({
            id,
            text,
            menuId: submenuItem.parentId,
            parentId: submenuItem.id,
            url:
              route === 'presunto/modify'
                ? 'layout/presuntos/list'
                : `/layout/${route}`,
          });
        }
      });
    });
  }

  selectOption(
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    option: any,
    submenuItemId: number,
    submenuItemParentId: number,
  ): void {
    const { id, url } = option;

    /** Marcamos como seleccionada/deseleccionada la opción */
    this.menuOptions.forEach((item) => {
      this.activeOptions[item.id] =
        item.id === id &&
        item.parentId === submenuItemId &&
        item.menuId === submenuItemParentId;
    });

    this.router.navigateByUrl(url);
  }
}
