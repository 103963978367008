/**
 * Enumeración de los errores del módulo de órdenes de reparación devueltos
 * en los servicios y tratados en los metodos error-handler
 */
export enum WOErrorTypes {
  WORK_ORDER_LOAD_REFERENCES_UNKNOWN = 0,
  WORK_ORDERS_LOAD_UNKNOWN = 1,
  WORK_ORDER_SAVE_UNKNOWN = 2,
  WORK_ORDER_LOAD_CUSTOMERS_UNKNOWN = 3,
  WORK_ORDERS_LABOR_UNKNOWN = 4,
  WORK_ORDERS_DETAIL_UNKNOWN = 5,
  WORK_ORDERS_DETAIL_SAVE_UNKNOWN = 6,
  WORK_ORDERS_DETAIL_DELETE_UNKNOWN = 7,
  WORK_ORDER_SIGNATURE_UNKNOWN = 8,
  BRANDS_LOAD_UNKNOWN = 9,
  MECHANICS_LOAD_UNKNOWN = 10,
  WORK_ORDERS_LABOR_SAVE_UNKNOWN = 11,
  WORK_ORDERS_LABOR_DELETE_UNKNOWN = 12,
}
