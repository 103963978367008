import { APP_BASE_HREF } from '@angular/common';
/** Angular core */
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

/** Routing */
import { AppRoutingModule } from './app-routing.module';

import { AuthenticationService, CoreModule } from '@core/index';
import { LayoutModule } from '@layout/index';
/** Módulos */
import { SharedModule, TranslationService } from '@shared/index';

/** Http */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/** Módulo core */
import { AuthInterceptorService } from '@core/index';

/** Sentry */
import * as Sentry from '@sentry/angular';

/** Incicializamos la aplicación cargando las traducciones. Si devuelve error,
 *  no cargará la página
 */
export function initializeApp(
  translationService: TranslationService,
  authenticationService: AuthenticationService,
) {
  return async () => {
    const userSession = window.location.pathname.includes('login')
      ? null
      : authenticationService.checkUserLoggedIn(true);

    await translationService.setTranslations(userSession?.lng ?? 'es');
    await translationService.setDatepickerTranslations(
      userSession?.lng ?? 'es',
    );
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    LayoutModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TranslationService, AuthenticationService, Sentry.TraceService],
      multi: true,
    },
    /** Para decirle a Angular que la aplicación está partiendo de un subdirectorio en
     *  la aplicación web
     */
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
