/** Angular core */
import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public notifications: any[];

  constructor() {
    this.notifications = [];
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  showError(message: string | TemplateRef<any>, options: any = {}) {
    this.show(message, { classname: 'bg-danger text-light', delay: 3000 });
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  showSuccess(message: string | TemplateRef<any>, options: any = {}) {
    this.show(message, { classname: 'bg-success text-light', delay: 3000 });
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  remove(notification: any) {
    this.notifications = this.notifications.filter((t) => t !== notification);
  }

  clear() {
    this.notifications.splice(0, this.notifications.length);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  show(message: string | TemplateRef<any>, options: any = {}) {
    this.notifications.push({ message, ...options });
  }
}
