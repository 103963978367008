<app-header></app-header>
<app-menu></app-menu>
<main
  class="main"
  [ngClass]="{
    'main--expanded':
      (menuExpansionService.menuOpen$ | async) === false || !largeSize,
  }">
  <router-outlet></router-outlet>
</main>
