/**
 * Guardia para proteger el acceso a las rutas de la aplicación sin estar autenticado
 */

/** Angular core */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/** Router */
import { CanLoad, Route, UrlSegment } from '@angular/router';
import {
  AuthenticationConfigService,
  AuthenticationService,
} from '@core/index';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanLoad {
  constructor(
    private authenticationService: AuthenticationService,
    private configService: AuthenticationConfigService,
  ) {}

  /** Método usado en los *.routing-module.ts para evitar que se pueda acceder a las rutas si
   *  estar autenticado -- NOTA: Parece deprecado, mirar sustituto
   */
  canLoad(
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    route: Route,
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isUserLoggedIn = this.authenticationService.checkUserLoggedIn();
    if (!isUserLoggedIn) {
      window.location.href = '/login';
      return false;
    }

    this.configService.loadConfiguration().subscribe({
      error: () => {
        window.location.href = '/login';
        return false;
      },
    });

    return true;
  }
}
