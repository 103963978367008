/** Angular core */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

/** Módulo core */
import { AuthenticationService, Session } from '@core/index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  /** Para gestionar la apertura y cierre del menú */
  @Input() menuOpen: boolean;
  @Output() toggle: EventEmitter<void> = new EventEmitter<void>();

  protected userSession$: Observable<Session>;
  protected userInfoCollapsed: boolean;
  protected userInfoMenuShow: boolean;
  protected language: string;

  constructor(
    protected authenticationService: AuthenticationService,
    private router: Router,
  ) {
    this.menuOpen = false;
    this.userSession$ = this.authenticationService.userSession$;
    this.userInfoCollapsed = true;
    this.language = navigator.language;
    this.userInfoMenuShow = false;
  }

  handleClickMenu(): void {
    this.toggle.emit();
  }

  toggleUserInfoMenu(): void {
    this.userInfoMenuShow = !this.userInfoMenuShow;
  }

  toggleUserInfoWindow(): void {
    this.userInfoCollapsed = !this.userInfoCollapsed;
    if (this.userInfoMenuShow) this.userInfoMenuShow = false;
  }

  goHome(): void {
    this.router.navigate(['layout/home']);
  }

  logout(): void {
    this.authenticationService.handleLogout();
    window.location.href = '/';
  }
}
