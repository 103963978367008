/**
 * Enumeración de los errores de las ayudas de las tablas de facturación devueltos
 * en los servicios y tratados en los metodos error-handler
 */
export enum InvoicingErrorTypes {
  VAT_LOAD = 0,
  PAYMENT_METHODS_LOAD = 1,
  PAYMENT_DAYS_LOAD = 2,
  CUSTOMER_TYPES_LOAD = 3,
}
