/**
 * Conjunto de servicios para las mutaciones de la bbdd dentro del módulo de
 * almacén y consumido transversalmente en toda la aplicación
 */

/** Angular core */
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  concatMap,
  map,
  of,
  take,
  tap,
  throwError,
} from 'rxjs';

/** Http */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

/** Módulo core */
import {
  AuthenticationConfigService,
  AuthenticationService,
  Brand,
  BrandsErrorTypes,
  FamiliesErrorTypes,
  Family,
  MechanicsErrorTypes,
  Session,
  brandsErrorHandler,
  familiesErrorHandler,
  mechanicsErrorHandler,
} from '@core/index';
import { TranslationPipe } from '@shared/index';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WarehouseHelpsService {
  private headers: HttpHeaders;
  private _apiUrl: string;
  /** CREAR MODELOS PARA MECÁNICOS Y REFERENCIAS */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public mechanics$: Observable<any>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public references$: Observable<any>;
  public brands$: Observable<Brand[]>;

  /** Observables y sus correspondientes subjects para los diferentes listados
   * Subject: private para proteger el acceso
   * Observable: public para ser accedido de forma controlada mediante el método pipe
   */
  private _errorMechanicsList: BehaviorSubject<string>;
  public errorMechanicsList$: Observable<string>;
  private _errorBrandsList: BehaviorSubject<string>;
  public errorBrandsList$: Observable<string>;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private translationPipe: TranslationPipe,
  ) {
    /** Parametrizamos la llamada http a la API con los parámetros de configuración obtenidos */
    this._apiUrl = AuthenticationConfigService.apiConfiguration.value
      ? AuthenticationConfigService.apiConfiguration.value?.url
      : '';

    this._errorMechanicsList = new BehaviorSubject<string>(null);
    this.errorMechanicsList$ = this._errorMechanicsList.asObservable();
  }

  resetErrorCode(): void {
    this._errorMechanicsList.next(null);
  }

  /** Método para obtener la lista de mecánicos */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  loadMechanics(): any {
    let url = '';
    let language = 'es';
    this.mechanics$ = this.authenticationService.userSession$.pipe(
      take(1),
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/mecanicos?lng=${language}`;
      }),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(
          map((response) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                mechanicsErrorHandler(
                  MechanicsErrorTypes.MECHANICS_LOAD_UNKNOWN,
                ),
              );
              this._errorMechanicsList.next(errorMessage);
            }
            if (response.success) {
              const { data: mechanics } = response;
              return mechanics;
            }
          }),
          catchError((httpError: HttpErrorResponse) => {
            /** Cargamos el código de error en un observable para ser recogido en su
             *  subscripción dentro del componente: allí se muestra el error.
             */
            if (httpError?.error?.message) {
              const { error } = httpError;

              /** LOG */
              if (environment.development)
                console.error(
                  'ERROR: Catch error al cargar los mecánicos',
                  error,
                );

              this._errorMechanicsList.next(error.message);
            } else {
              const errorMessage = this.translationPipe.transform(
                mechanicsErrorHandler(
                  MechanicsErrorTypes.MECHANICS_LOAD_UNKNOWN,
                ),
              );
              this._errorMechanicsList.next(errorMessage);
            }
            return of(null);
          }),
        );
      }),
    );
  }

  /** Método para obtener la lista de mecánicos */
  loadBrands(): Observable<Brand[]> {
    let url = '';
    let language = 'es';
    this.brands$ = this.authenticationService.userSession$.pipe(
      take(1),
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/marcas?lng=${language}`;
      }),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(
          map((response) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                brandsErrorHandler(BrandsErrorTypes.BRANDS_LOAD_UNKNOWN),
              );
              this._errorMechanicsList.next(errorMessage);
            }
            if (response.success) {
              const { data: mechanics } = response;
              return mechanics;
            }
          }),
          catchError((httpError: HttpErrorResponse) => {
            /** Cargamos el código de error en un observable para ser recogido en su
             *  subscripción dentro del componente: allí se muestra el error.
             */
            if (httpError?.error?.message) {
              const { error } = httpError;

              /** LOG */
              if (environment.development)
                console.error('ERROR: Catch error al cargar las marcas', error);

              this._errorMechanicsList.next(error.message);
            } else {
              const errorMessage = this.translationPipe.transform(
                brandsErrorHandler(BrandsErrorTypes.BRANDS_LOAD_UNKNOWN),
              );
              this._errorMechanicsList.next(errorMessage);
            }
            return of(null);
          }),
        );
      }),
    );
    return this.brands$;
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getReferenceData(referenceCode: string): Observable<any> {
    let url = '';
    return this.authenticationService.userSession$.pipe(
      tap(
        (userSession: Session) =>
          (url = `${this._apiUrl}/empresas/${userSession.empOmi}/referencias/${referenceCode}`),
      ),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(
          map((response) => {
            if (response.success) {
              const { data: reference } = response;
              return {
                status: 200,
                data: reference,
              };
            }
            return {
              status: response.status,
              data: null,
            };
          }),
        );
      }),
    );
  }

  loadFamilies(): Observable<Family[]> {
    let url = '';
    let language = 'es';
    return this.authenticationService.userSession$.pipe(
      take(1),
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/familias?lng=${language}`;
      }),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(
          map((response) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                familiesErrorHandler(FamiliesErrorTypes.FAMILIES_LOAD_UNKNOWN),
              );
              this._errorMechanicsList.next(errorMessage);
            }
            if (response.success) {
              const { data: mechanics } = response;
              return mechanics;
            }
          }),
          catchError((httpError: HttpErrorResponse) => {
            /** Cargamos el código de error en un observable para ser recogido en su
             *  subscripción dentro del componente: allí se muestra el error.
             */
            if (httpError?.error?.message) {
              const { error } = httpError;

              /** LOG */
              if (environment.development)
                console.error(
                  'ERROR: Catch error al cargar las familias',
                  error,
                );

              this._errorMechanicsList.next(error.message);
            } else {
              const errorMessage = this.translationPipe.transform(
                familiesErrorHandler(FamiliesErrorTypes.FAMILIES_LOAD_UNKNOWN),
              );
              this._errorMechanicsList.next(errorMessage);
            }
            return of(null);
          }),
        );
      }),
    );
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getPriceAndDiscount(referenceData: any): Observable<any> {
    let url = '';

    return this.authenticationService.userSession$.pipe(
      tap(
        (userSession: Session) =>
          (url = `${this._apiUrl}/empresas/${userSession.empOmi}/referencias/${referenceData.refer}/precio`),
      ),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url, referenceData).pipe(
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          map((response: any) => {
            if (response.success) {
              const { data: priceObject } = response;
              return {
                status: 200,
                data: priceObject,
              };
            }
            return {
              status: response.status,
              data: null,
            };
          }),
        );
      }),
    );
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getReferenceVAT(vatInput: any): Observable<any> {
    let url = '';

    const { refer } = vatInput;
    return this.authenticationService.userSession$.pipe(
      tap(
        (userSession: Session) =>
          (url = `${this._apiUrl}/empresas/${userSession.empOmi}/referencias/${refer}/iva`),
      ),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url, vatInput);
      }),
    );
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getAllReferenceData(referenceData: any): Observable<any> {
    const references$ = this.getReferenceData(referenceData.refer);
    const priceDiscount$ = this.getPriceAndDiscount(referenceData);
    const result = {
      descriptionObject: null,
      priceAndDiscountObject: null,
    };

    // return concat(references$, priceDiscount$).pipe(
    return references$.pipe(
      tap((resp) => (result.descriptionObject = resp.data)),
      concatMap(() => priceDiscount$),
      tap((resp) => (result.priceAndDiscountObject = resp.data)),
      map(() => result),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del alta/modificción WO: allí se muestra el error.
         */
        if (httpError) {
          if (httpError.error) {
            const { error } = httpError;
            /** LOG */
            if (environment.development)
              console.error(
                'ERROR: Catch error obtener datos referencia',
                error,
              );
          }

          return throwError(() => httpError);
        }
        return of(null);
      }),
    );
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getReferences(filter: string): Observable<any> {
    let url = '';
    return this.authenticationService.userSession$.pipe(
      tap((userSession: Session) => {
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/referencias${filter}`;
      }),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(
          take(1),
          map((response) => {
            if (response?.success)
              return {
                status: 200,
                data: response.data,
              };
            return {
              status: 500,
              data: [],
            };
          }),
          catchError((error) => {
            return throwError(() => error);
          }),
        );
      }),
    );
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getCustomerData(clientCode: number): Observable<any> {
    let url = '';
    return this.authenticationService.userSession$.pipe(
      tap(
        (userSession: Session) =>
          (url = `${this._apiUrl}/empresas/${userSession.empOmi}/clientes?cli=${clientCode}`),
      ),
      concatMap(() => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return this.http.get<any>(url).pipe(
          take(1),
          map((response) => {
            if (response.success) {
              const { data: customers } = response;
              return {
                status: 200,
                data: customers[0],
              };
            }

            return {
              status: response.status,
              data: null,
            };
          }),
          catchError((error) => {
            return error;
          }),
        );
      }),
    );
  }
}
