/**
 * Conjunto de servicios para las mutaciones de la bbdd dentro del módulo de
 * facturación y consumido transversalmente en toda la aplicación
 */

/** Angular core */
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  concatMap,
  map,
  of,
  tap,
} from 'rxjs';

/** Http */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

/** Módulo core */
import {
  APIResponse,
  AuthenticationConfigService,
  AuthenticationService,
  InvoicingErrorTypes,
  Session,
  customerTypesErrorHandler,
} from '@core/index';
import {
  paymentDaysErrorHandler,
  paymentMethodErrorHandler,
  vatErrorHandler,
} from '@core/index';
import { TranslationPipe } from '@shared/index';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoicingHelpsService {
  private headers: HttpHeaders;
  private _authType: string;
  private _apiUrl: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _contentType: any;

  /** Observables y sus correspondientes subjects para los diferentes listados
   * Subject: private para proteger el acceso
   * Observable: public para ser accedido de forma controlada mediante el método pipe
   */
  /** CREAR MODELOS PARA LAS DIFERENTES TABLAS */
  public vats$: Observable<any[]>;
  private _vats: BehaviorSubject<any[]>;
  public paymentMethods$: Observable<any[]>;
  private _paymentMethods: BehaviorSubject<any[]>;
  public paymentDays$: Observable<any[]>;
  private _paymentDays: BehaviorSubject<any[]>;
  public customerTypes$: Observable<any[]>;
  private _customerTypes: BehaviorSubject<any[]>;
  private _errorVatList: BehaviorSubject<string>;
  public errorVatList$: Observable<string>;
  private _errorPaymentMethodList: BehaviorSubject<string>;
  public errorPaymentMethodList$: Observable<string>;
  private _errorPaymentDaysList: BehaviorSubject<string>;
  public errorPaymentDaysList$: Observable<string>;
  private _errorCustomerTypesList: BehaviorSubject<string>;
  public errorCustomerTypesList$: Observable<string>;

  constructor(
    private http: HttpClient,
    private translationPipe: TranslationPipe,
    private authenticationService: AuthenticationService,
  ) {
    this._authType = '';
    this._apiUrl = '';

    /** Parametrizamos la llamada http a la API con los parámetros de configuración obtenidos */
    this._apiUrl = AuthenticationConfigService.apiConfiguration.value
      ? AuthenticationConfigService.apiConfiguration.value?.url
      : '';

    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    this._vats = new BehaviorSubject<any[]>([]);
    this.vats$ = this._vats.asObservable();
    this._paymentMethods = new BehaviorSubject<any[]>([]);
    this.paymentMethods$ = this._paymentMethods.asObservable();
    this._paymentDays = new BehaviorSubject<any[]>([]);
    this.paymentDays$ = this._paymentDays.asObservable();
    this._customerTypes = new BehaviorSubject<any[]>([]);
    this.customerTypes$ = this._customerTypes.asObservable();
    this._errorVatList = new BehaviorSubject<string>(null);
    this.errorVatList$ = this._errorVatList.asObservable();
    this._errorPaymentMethodList = new BehaviorSubject<string>(null);
    this.errorPaymentMethodList$ = this._errorPaymentMethodList.asObservable();
    this._errorPaymentDaysList = new BehaviorSubject<string>(null);
    this.errorPaymentDaysList$ = this._errorPaymentDaysList.asObservable();
    this._errorCustomerTypesList = new BehaviorSubject<string>(null);
    this.errorCustomerTypesList$ = this._errorCustomerTypesList.asObservable();
  }

  /** Ponemos los streams de los observables a null para reiniciar */
  resetErrorCode(): void {
    this._errorVatList.next(null);
    this._errorPaymentMethodList.next(null);
    this._errorPaymentDaysList.next(null);
    this._errorCustomerTypesList.next(null);
  }

  /** Carga de registros IVA */
  loadVAT(): void {
    const url = `${this._apiUrl}/ivas`;
    this.vats$ = this.http.get<any>(url).pipe(
      map((response) => {
        if (!response || !response.success) {
          const errorMessage = this.translationPipe.transform(
            vatErrorHandler(InvoicingErrorTypes.VAT_LOAD),
          );
          this._errorVatList.next(errorMessage);
        }

        if (response.success) {
          const { data: vats } = response;
          return vats;
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error(
              'ERROR: Catch error al cargar los registros de IVA',
              error,
            );

          this._errorVatList.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            vatErrorHandler(InvoicingErrorTypes.VAT_LOAD),
          );
          this._errorVatList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Carga de registros de métodos de pago */
  loadPaymentMethods(): void {
    /** Si no existe el fichero de conexión con la API devuelve null */
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return;
    }

    let language = 'es';
    let url = '';
    this.paymentMethods$ = this.authenticationService.userSession$.pipe(
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/modos-pago?lng=${language}`;
      }),
      concatMap(() => {
        return this.http.get<APIResponse>(url).pipe(
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          map((response: any) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                paymentMethodErrorHandler(
                  InvoicingErrorTypes.PAYMENT_METHODS_LOAD,
                ),
              );
              this._errorPaymentMethodList.next(errorMessage);
            }

            if (response.success) {
              const { data: paymentMethods } = response;
              return paymentMethods;
            }
          }),
        );
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error(
              'ERROR: Catch error al cargar los modos de pago',
              error,
            );

          this._errorPaymentMethodList.next(error.messge);
        } else {
          const errorMessage = this.translationPipe.transform(
            paymentMethodErrorHandler(InvoicingErrorTypes.PAYMENT_METHODS_LOAD),
          );
          this._errorPaymentMethodList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Carga de registros de días de pago */
  loadPaymentDays(): void {
    /** Si no existe el fichero de conexión con la API devuelve null */
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return;
    }
    let language = 'es';
    let url = '';
    this.paymentDays$ = this.authenticationService.userSession$.pipe(
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/dias-pago?lng=${language}`;
      }),
      concatMap(() => {
        return this.http.get<APIResponse>(url).pipe(
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          map((response: any) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                paymentDaysErrorHandler(InvoicingErrorTypes.PAYMENT_DAYS_LOAD),
              );
              this._errorPaymentDaysList.next(errorMessage);
            }
            if (response.success) {
              const { data: paymentDays } = response;
              return paymentDays;
            }
          }),
        );
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error(
              'ERROR: Catch error al cargar los días de pago',
              error,
            );

          this._errorPaymentDaysList.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            paymentDaysErrorHandler(InvoicingErrorTypes.PAYMENT_DAYS_LOAD),
          );
          this._errorPaymentDaysList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }

  /** Carga de registros de tipos de cliente */
  loadCustomerTypes(): void {
    /** Si no existe el fichero de conexión con la API devuelve null */
    if (!AuthenticationConfigService.apiConfiguration?.value) {
      return;
    }
    let language = 'es';
    let url = '';
    this.customerTypes$ = this.authenticationService.userSession$.pipe(
      tap((userSession: Session) => {
        language = userSession.lng;
        url = `${this._apiUrl}/empresas/${userSession.empOmi}/tipos-cliente?lng=${language}`;
      }),
      concatMap(() => {
        return this.http.get<APIResponse>(url).pipe(
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          map((response: any) => {
            if (!response || !response.success) {
              const errorMessage = this.translationPipe.transform(
                customerTypesErrorHandler(
                  InvoicingErrorTypes.CUSTOMER_TYPES_LOAD,
                ),
              );
              this._errorCustomerTypesList.next(errorMessage);
            }

            if (response.success) {
              const { data: customerTypes } = response;
              return customerTypes;
            }
          }),
        );
      }),
      catchError((httpError: HttpErrorResponse) => {
        /** Cargamos el código de error en un observable para ser recogido en su
         *  subscripción dentro del componente: allí se muestra el error.
         */
        if (httpError?.error?.message) {
          const { error } = httpError;

          /** LOG */
          if (environment.development)
            console.error('ERROR: Catch error al cargar las provincias', error);

          this._errorCustomerTypesList.next(error.message);
        } else {
          const errorMessage = this.translationPipe.transform(
            customerTypesErrorHandler(InvoicingErrorTypes.CUSTOMER_TYPES_LOAD),
          );
          this._errorCustomerTypesList.next(errorMessage);
        }
        return of(null);
      }),
    );
  }
}
