import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/index';
import { take, tap } from 'rxjs';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  protected authenticationService: AuthenticationService;
  protected avatar: string =
    'https://ui-avatars.com/api/?size=1&color=ed772d&background=ed772d';

  constructor(authenticationService: AuthenticationService) {
    this.authenticationService = authenticationService;
  }

  ngOnInit(): void {
    console.log('AvatarComponent ngOnInit');

    this.authenticationService.profileImage$
      .pipe(
        take(1),
        tap((profileImage) => {
          fetch(profileImage, { credentials: 'include' })
            .then((response) => {
              this.avatar =
                response.status === 200
                  ? profileImage
                  : `https://ui-avatars.com/api/?size=48&color=ed772d&background=ffffff&name=${this.authenticationService.userSession().nombre}`;
            })
            .catch(() => {
              console.warn('Failed to load avatar image');
            });
        }),
      )
      .subscribe()
      .unsubscribe();
  }
}
