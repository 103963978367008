/** Angular core */
import { Injectable } from '@angular/core';

/** Librerías  */
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number | null) {
  if (!Number.isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  }
  return '';
}

@Injectable()
export class FormatterService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (
        dateParts.length === 1 &&
        !Number.isNaN(Number.parseInt(dateParts[0]))
      ) {
        return { day: Number.parseInt(dateParts[0]), month: null, year: null };
      }
      if (
        dateParts.length === 2 &&
        !Number.isNaN(Number.parseInt(dateParts[0])) &&
        !Number.isNaN(Number.parseInt(dateParts[1]))
      ) {
        return {
          day: Number.parseInt(dateParts[0]),
          month: Number.parseInt(dateParts[1]),
          year: null,
        };
      }
      if (
        dateParts.length === 3 &&
        !Number.isNaN(Number.parseInt(dateParts[0])) &&
        !Number.isNaN(Number.parseInt(dateParts[1])) &&
        Number.isNaN(Number.parseInt(dateParts[2]))
      ) {
        return {
          day: Number.parseInt(dateParts[0]),
          month: Number.parseInt(dateParts[1]),
          year: Number.parseInt(dateParts[2]),
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? `${!Number.isNaN(date.day) ? padNumber(date.day) : ''}/${!Number.isNaN(date.month) ? padNumber(date.month) : ''}/${date.year}`
      : '';
  }
}
