import { DecimalPipe } from '@angular/common';
/** Angular core */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

/** Módulo shared */
import { SortColumn, SortDirection, TableState, sort } from '@shared/index';

interface SearchResult {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  data: any[];
  total: number;
}

@Injectable({ providedIn: 'root' })
export class IsiTableService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _data$ = new BehaviorSubject<any[]>([]);
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private _originalData$ = new BehaviorSubject<any[]>([]);
  private _search$ = new Subject<void>();
  private _total$ = new BehaviorSubject<number>(0);
  private searchFunction: (...args: any[]) => any;

  private _state: TableState = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(private decimalPipe: DecimalPipe) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        switchMap(() => this._search(this.searchFunction)),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result) => {
        this._data$.next(result.data);
        this._total$.next(result.total);
      });
  }

  get data$() {
    return this._data$.asObservable();
  }

  get page() {
    return this._state.page;
  }

  set page(page: number) {
    this._set({ page });
  }

  get pageSize() {
    return this._state.pageSize;
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  get searchTerm() {
    return this._state.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }

  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }

  get total$() {
    return this._total$.asObservable();
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  loadData(
    data$: Observable<any[]>,
    fn: (...args: any[]) => any,
    initialPageSize: number,
  ) {
    this.searchFunction = fn;
    this._state.pageSize = initialPageSize;

    data$?.pipe().subscribe((data) => {
      this._data$.next(data);
      /** Nos guardamos una copia inalterable para hacer las búsquedas */
      this._originalData$.next(data);
      this.searchTerm = '';
    });
  }

  private _set(patch: Partial<TableState>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(fn: (...args: any[]) => any): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    // 1. filter
    let data = [...this._originalData$.value].filter((el) =>
      fn(el.listObject, searchTerm, this.decimalPipe),
    );

    // 2. sort
    data = sort(data, sortColumn, sortDirection);

    const total = data.length;

    // 3. paginate
    data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({ data, total });
  }
}
